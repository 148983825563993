import React from 'react';
import { sumBy, meanBy, map } from 'lodash';
import { splitByThousands, convertToThousands } from '../../../helpers/NumberFormatter';

export {
    sumFooter,
    avgFooter,
    convertSumFooter,
    textFooter,
    spaceFooter,
}

function getFooter(props, fn, forceAccessor) {
    const { column: {id, accessor}, data } = props;
    let key = forceAccessor;
    if(!key){
        if(accessor && typeof accessor == 'string')
            key = accessor;
        else
            key = id;
    }
    const filtered = data.map(item=>{
        if(typeof item[key] != 'number' || isNaN(item[key]))
            item[key] = parseInt(item[key]) || 0;
        if(!item[key]) {
            item[key] = parseInt(item._original[key]) || 0;
        }
        return item;
    });
    /*.filter((item)=>{
        return item[key]
    });
    */
    return filtered.length > 0  ? fn(filtered, key) : 0;
}

function sumFooter(props, obj, forceAccessor) {
    return <span>{splitByThousands(getFooter(props, sumBy, forceAccessor))}</span>
}

function avgFooter(props, obj, forceAccessor, suffix='%') {
    const value = Math.round(getFooter(props, meanBy, forceAccessor)*100)/100;
    return <span title={`${splitByThousands(value)}${suffix}`}>{convertToThousands(value)}{suffix}</span>
}

function convertSumFooter(props, obj, forceAccessor) {
    const value = Math.round(getFooter(props, sumBy, forceAccessor)*100)/100;
    return <span title={splitByThousands(value)}>{convertToThousands(value)}</span>
}

function textFooter(props, obj, text='Total: ') {
    return <span>{text}</span>;
}

function spaceFooter(props, obj) {
    return textFooter(props, obj, '');
}